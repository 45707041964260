var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"clearable":"","outlined":"","dense":"","height":"40px","label":"Search name, email, or etc.","prepend-inner-icon":"mdi-magnify","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"auto-select-first":"","clearable":"","menu-props":{
          bottom: true,
          offsetY: true,
        },"append-icon":"mdi-chevron-down","items":_vm.state_list,"search-input":_vm.state_search,"loading":_vm.state_list_loading,"item-text":"name","item-value":"id","outlined":"","dense":"","no-filter":"","height":"40px","label":"Filter by State"},on:{"update:searchInput":function($event){_vm.state_search=$event},"update:search-input":function($event){_vm.state_search=$event}},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"auto-select-first":"","clearable":"","menu-props":{
          bottom: true,
          offsetY: true,
        },"append-icon":"mdi-chevron-down","items":_vm.city_list,"search-input":_vm.city_search,"loading":_vm.city_list_loading,"item-text":"name","item-value":"id","outlined":"","dense":"","no-filter":"","height":"40px","label":"Filter by City"},on:{"update:searchInput":function($event){_vm.city_search=$event},"update:search-input":function($event){_vm.city_search=$event}},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"5"}},[_c('v-btn',{staticClass:"text-capitalize px-7 rounded-lg",attrs:{"color":"primary","outlined":"","height":"40px"},on:{"click":function($event){return _vm.addEca({ show: true, id: '', type: 'add' })}}},[_c('v-icon',{attrs:{"size":"16","left":""}},[_vm._v("mdi-plus")]),_vm._v("Add New ECA Body ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"loading-text":"Please wait.... Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.created))+" ")]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.remarks",fn:function(ref){
        var item = ref.item;
return [_c('div',{},[(item.remarks)?_c('div',[_vm._v(_vm._s(item.remarks))]):_vm._e(),_c('v-edit-dialog',{attrs:{"large":"","transition":"slide-y-transition"},on:{"save":function($event){return _vm.onSave(item.id, 'remarks')},"open":function($event){_vm.editDialog.remarks = item.remarks}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"my-4"},[_vm._v("Remarks")]),_c('v-textarea',{staticClass:"rounded-lg",attrs:{"rows":"3","type":"text","flat":"","outlined":"","dense":""},model:{value:(_vm.editDialog.remarks),callback:function ($$v) {_vm.$set(_vm.editDialog, "remarks", $$v)},expression:"editDialog.remarks"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"primary--text d-flex align-center text-caption"},[(item.remarks)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-pencil")]),_vm._v(" Edit Remarks ")],1):_c('span',[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-plus")]),_vm._v(" Add Remarks ")],1)])])],1)]}},{key:"item.contact",fn:function(ref){
        var item = ref.item;
return [_c('div',{},[(item.user.mobile || item.user.email || item.full_address)?_c('div',[(item.user.mobile)?_c('div',[_vm._v(_vm._s(item.user.mobile_display))]):_vm._e(),(item.user.email)?_c('div',[_vm._v(_vm._s(item.user.email))]):_vm._e(),(item.full_address)?_c('div',[_vm._v(_vm._s(item.full_address))]):_vm._e()]):_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{attrs:{"color":"success","label":item.is_active ? 'Active' : 'In-active'},on:{"change":function($event){return _vm.changeStatus(item.is_active, item.id)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary","height":"32px"},on:{"click":function($event){return _vm.addEca({
              show: true,
              type: 'edit',
              id: item.id,
            })}}},[_vm._v(" edit ")]),_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary","height":"32px"},on:{"click":function($event){_vm.toggleConfirmationDialog({ show: true, action: 'delete' }),
              (_vm.user_id = item.id)}}},[_vm._v(" delete ")])],1)]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1),_c('ConfirmationDialog',{attrs:{"height":"130px","width":"130px"},on:{"confirmed":_vm.deleteUser},scopedSlots:_vm._u([{key:"confirmBtnText",fn:function(){return [_vm._v(" Yes Delete ")]},proxy:true}])}),_c('add-eca',{on:{"reload-list":_vm.getEcaList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }