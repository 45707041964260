<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    max-width="565px"
    content-class="rounded-xl"
  >
    <v-overlay class="justify-center align-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="8"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div>
          <div class="title-name">
            {{ getAddEca.type == "edit" ? "Edit" : "Add" }} ECA Body
          </div>
          <div class="sub-title py-1">Please enter details here</div>
        </div>
        <v-btn icon @click="addEca({ show: false, id: '', type: '' })">
          <v-icon size="26">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form class="pt-5" lazy-validation ref="addEcaForm">
          <v-row no-gutters>
            <v-col cols="3" class="py-4 mb-3">
              <input
                type="file"
                ref="uploadProfile"
                class="d-none"
                @change="uploadPicture"
                accept="image/*"
              />
              <div
                class="logo-div align-center text-center pointer"
                @click="$refs.uploadProfile.click()"
              >
                <span v-if="!previewFile">
                  <img
                    height="25px"
                    src="@/assets/images/upload-doc.svg"
                    class="mt-6"
                  />
                  <div class="text-caption">Upload Logo</div>
                </span>
                <v-img
                  v-else
                  min-height="108px"
                  max-height="108px"
                  :lazy-src="blurImg"
                  class="pointer"
                  style="object-fit: cover; border-radius: 5px"
                  :src="previewFile"
                ></v-img>
              </div>
            </v-col>
            <!-- <v-col cols="6"></v-col> -->

            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                label="Name"
                :rules="[$rules.required]"
                height="42px"
                type="text"
                outlined
                dense
                class="rounded-lg"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <div style="width: 17%">
                  <v-select
                    :menu-props="{
                      bottom: true,
                      offsetY: true,
                    }"
                    v-model="selectedCountry"
                    :items="country_list"
                    item-text="name"
                    return-object
                    height="42px"
                    auto-select-first
                    dense
                    hide-details
                    outlined
                    @input="validate_number"
                    @change="getStateList"
                    class="rounded-lg"
                  >
                    <template v-slot:selection="data">
                      <span class="mr-2">
                        <v-img
                          :src="data.item.flag"
                          height="20"
                          width="25"
                          class="rounded"
                          v-if="data.item.flag"
                        ></v-img>
                        <div v-else>
                          {{ data.item.iso2 }}
                        </div>
                      </span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title class="d-flex align-center">
                          <v-img
                            :src="data.item.flag"
                            height="20"
                            max-width="25"
                            class="rounded mr-2"
                            v-if="data.item.flag"
                          ></v-img>
                          <div v-else class="mr-2">
                            {{ data.item.iso2 }}
                          </div>
                          {{ data.item.name }}
                          ({{ data.item.phone_code }})
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </div>
                <div style="width: 81%">
                  <v-text-field
                    v-model="form.mob_no"
                    label="Mobile Number"
                    :rules="[isMobileValid]"
                    @input="validate_number"
                    hide-spin-buttons
                    height="42px"
                    type="number"
                    outlined
                    dense
                    @keydown="$rules.blockInvalidChar"
                    :prefix="selectedCountry ? selectedCountry.phone_code : ''"
                    class="rounded-lg"
                  >
                  </v-text-field>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.email"
                label="Email"
                :rules="[$rules.required, $rules.email]"
                height="42px"
                type="email"
                outlined
                dense
                class="rounded-lg"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                auto-select-first
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="form.state"
                :items="state_list"
                :search-input.sync="state_search"
                :loading="state_list_loading"
                item-text="name"
                item-value="id"
                flat
                outlined
                label="Select State"
                dense
                no-filter
                return-object
                class="rounded-lg"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                auto-select-first
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="form.city"
                :items="city_list"
                :search-input.sync="city_search"
                :loading="city_list_loading"
                :disabled="form.state == null"
                item-text="name"
                item-value="id"
                flat
                outlined
                dense
                no-filter
                return-object
                label="Select City"
                class="rounded-lg"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                type="text"
                v-model="form.address"
                flat
                outlined
                dense
                label="Address"
                class="rounded-lg"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                type="text"
                v-model="form.remarks"
                flat
                outlined
                dense
                label="Remarks"
                class="rounded-lg"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="submit"
          :loading="btnLoading"
          color="primary"
          class="text-capitalize px-8 my-2 rounded-lg"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "addEca",
  data() {
    return {
      loading: false,
      blurImg: require("@/assets/images/blurImg.png"),
      btnLoading: false,
      form: {
        name: "",
        mob_no: "",
        email: "",
        state: null,
        city: null,
        profile_pic: "",
        address: "",
        remarks: "",
      },
      previewFile: "",
      selectedCountry: "",
      isMobileValid: true,

      state_list: [],
      state_search: "",
      state_list_loading: false,

      city_list: [],
      city_search: "",
      city_list_loading: false,
    };
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },

    state_search: _.debounce(function (query) {
      if (this.state_list_loading) return;

      if (query && !this.form.state) {
        this.getStateList();
      }
    }, 1000),

    city_search: _.debounce(function (query) {
      if (this.city_list_loading) return;

      if (query && !this.form.city) {
        this.getCityList();
      }
    }, 1000),
  },

  computed: {
    ...mapGetters({
      getAddEca: "admin/getAddEca",
      country_list: "getCountryList",
    }),

    show: {
      get() {
        return this.getAddEca.show;
      },
      set(value) {
        this.addEca({ show: value });
      },
    },
  },

  methods: {
    ...mapActions({
      addEca: "admin/addEca",
      showSnackbar: "app/showSnackbar",
    }),

    validate_number() {
      if (this.form.mob_no && this.selectedCountry)
        this.isMobileValid = this.$rules.phone_number_validator(
          this.form.mob_no,
          this.selectedCountry.iso2
        );
    },

    async openModal() {
      this.loading = true;

      if (this.getAddEca.type === "edit") {
        await this.getEcaDetail();

        if (this.mobile_country_code) {
          this.selectedCountry = _.find(this.country_list, [
            "iso2",
            this.mobile_country_code,
          ]);
        } else {
          this.selectedCountry = _.find(this.country_list, ["iso2", "IN"]);
        }
      } else {
        this.selectedCountry = _.find(this.country_list, ["iso2", "IN"]);
      }

      this.loading = false;
    },

    modalClosed() {
      this.$refs.addEcaForm.reset();

      this.form = {
        name: "",
        mob_no: "",
        email: "",
        state: null,
        city: null,
        profile_pic: "",
        address: "",
        remarks: "",
      };

      this.previewFile = "";
    },

    getStateList() {
      this.state_list_loading = true;

      const params = {
        apply_pagination: false,
        search: this.state_search,
        country_id: this.selectedCountry.id,
      };

      const onSuccess = (res) => {
        this.state_list = res.data.data;
      };

      const onFinally = () => {
        this.state_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.master.state.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getCityList() {
      this.city_list_loading = true;

      const params = {
        apply_pagination: false,
        search: this.city_search,
        state_id: this.form.state ? this.form.state.id : null,
      };

      const onSuccess = (res) => {
        this.city_list = res.data.data;
      };

      const onFinally = () => {
        this.city_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    uploadPicture() {
      let input = this.$refs.uploadProfile;
      let file = input.files;
      this.form.profile_pic = file[0];
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewFile = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },

    submit() {
      if (this.$refs.addEcaForm.validate()) {
        const data = new FormData();

        data.append("name", this.form.name);
        data.append("email", this.form.email);

        if (this.form.mob_no) {
          const phone = this.$utils.getPhoneNumberInfo(
            this.form.mob_no,
            this.selectedCountry.iso2
          );
          data.append("mobile", phone.phone_number);
          data.append("mobile_code", phone.phone_code);
          data.append("mobile_country_code", phone.country_code);
        } else data.append("mobile", this.form.mob_no);

        if (this.form.city) data.append("city", this.form.city.id);
        if (this.form.state) data.append("state", this.form.state.id);

        data.append("remarks", this.form.remarks);
        data.append("address", this.form.address);
        data.append("logo", this.form.profile_pic);

        this.btnLoading = true;

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.addEca({ show: false, id: "", type: "" });
          this.$emit("reload-list");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        if (this.getAddEca.id) {
          const params = {
            eca_body_id: this.getAddEca.id,
          };

          return this.$request(this.$keys.PUT, this.$urls.eca.update, {
            params: params,
            data,
            onSuccess,
            onFinally,
          });
        } else {
          return this.$request(this.$keys.POST, this.$urls.eca.create, {
            data,
            onSuccess,
            onFinally,
          });
        }
      } else {
        this.$nextTick(() => {
          const el = document.getElementsByClassName("v-input error--text");
          if (el)
            el[0].scrollIntoView({
              block: "end",
              behavior: "smooth",
            });
          return;
        });
      }
    },

    getEcaDetail() {
      const params = {
        eca_body_id: this.getAddEca.id,
      };

      const onSuccess = (res) => {
        const data = res.data.data;

        this.form.name = data.name;
        this.form.role = data.role;
        this.form.remarks = data.remarks;

        const user = data.user;
        this.form.mob_no = user.mobile;
        this.form.email = user.email;

        if (user.mobile_country_code)
          this.mobile_country_code = user.mobile_country_code;
        else this.mobile_country_code = "IN";

        if (data.city) {
          this.form.city = data.city;
          this.city_list.push(data.city);
        }

        if (data.state) {
          this.form.state = data.state;
          this.state_list.push(data.state);
        }

        this.form.address = data.address;
        this.previewFile = data.logo;
      };

      return this.$request(this.$keys.GET, this.$urls.eca.detail, {
        params,
        onSuccess,
      });
    },
  },
};
</script>
<style scoped>
.title-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.sub-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  opacity: 0.6;
}
.logo-div {
  height: 110px;
  width: 110px;
  border-radius: 5px;
  border: 1px dashed #9e9e9e;
}
.document-div {
  height: 60px;
  /* width: 110px; */
  border-radius: 5px;
  border: 1px dashed #9e9e9e;
}
</style>
